import "../styles/ExchangeRateOthers.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";

import NavBar from "../components/NavBar";
import dolIcon from "../assets/icons/paid.svg";
import RateContainer from "../components/RateContainer";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

const ExchangeRateOthersPage = ({
  currencies,
  notTomanCurrencies,
  cryptos,
  coins,
  golds,
  noCategories,
  logoutHandler,
  updateCurrencyHandler,
  updateTomanCurrencyHandler,
  updateCoinHandler,
  updateGoldHandler,
  updateNoCategoryHandler,
  updateCryptoHandler,
}) => {
  const [updatedCurrencies, setUpdatedCurrencies] =
    useState(notTomanCurrencies);
  const [isEdited, setIsEdited] = useState(false);
  const [buyRateChange, setBuyRateChange] = useState({
    type: "plus",
    value: "",
  });
  const [sellRateChange, setSellRateChange] = useState({
    type: "plus",
    value: "",
  });
  const [rateMode, setRateMode] = useState(
    notTomanCurrencies[0]?.isAutoFill === true ? "automatic" : "manual"
  );

  const { REACT_APP_URL } = process.env;

  // Find the currency with the latest `updatedAt`
  const latestCurrency = notTomanCurrencies?.reduce((latest, item) => {
    return new Date(item?.updatedAt) > new Date(latest.updatedAt)
      ? item
      : latest;
  }, notTomanCurrencies[0]);

  useEffect(() => {
    setUpdatedCurrencies(notTomanCurrencies);
    setRateMode(
      notTomanCurrencies[0]?.isAutoFill === true ? "automatic" : "manual"
    );
  }, [notTomanCurrencies]);

  const handleInputChange = (id, field, value) => {
    const newCurrs = setUpdatedCurrencies.map((coin) => {
      if (coin.id === id) {
        return { ...coin, [field]: value.replace(/[^0-9.]/g, "") };
      }
      return coin;
    });

    setUpdatedCurrencies(newCurrs);
    setIsEdited(true);
  };

  const handleUpdateRates = () => {
    console.log(updatedCurrencies);
    updateCurrencyHandler(updatedCurrencies);
    setIsEdited(false);
  };

  const getRateChangeType = (rateChange) => {
    return rateChange.includes("-") ? "minus" : "plus";
  };

  const handleRateChange = (id, field, type, value) => {
    const newRateChange =
      type === "plus" ? value : `-${value.replace(/^-/, "")}`;
    const newCoins = updatedCurrencies.map((coin) => {
      if (coin.id === id) {
        return { ...coin, [field]: newRateChange };
      }
      return coin;
    });

    setUpdatedCurrencies(newCoins);
    setIsEdited(true);

    updateCurrencyHandler(newCoins);
    setIsEdited(false);
  };

  const handleRateModeChange = async (mode) => {
    try {
      setRateMode(mode); // Update local state immediately
      console.log(mode);

      await axios.post(`${REACT_APP_URL}/coin/rate-mode`, { rateMode: mode }); // Replace with your API endpoint
      console.log("Rate mode updated to:", mode);
    } catch (error) {
      console.error("Failed to update rate mode:", error);
    }
  };

  // Handle input change for buy and sell prices
  // const handleInputChange = (id, field, value, type) => {
  //   const numericValue = value.replace(/[^0-9.]/g, "");

  //   const newCurrencies = updatedCurrencies.map((currency) => {
  //     if (currency.id === id) {
  //       return { ...currency, [field]: { type, value: numericValue } };
  //     }
  //     return currency;
  //   });

  //   setUpdatedCurrencies(newCurrencies);
  //   setIsEdited(true);
  // };
  

  return (
    <div className="exchange-rate-others-container">
      <NavBar logoutHandler={logoutHandler} />
      <div className="flex-col">
        <RateContainer
          table={"Istanbul Currency"}
          data={currencies}
          updateHandler={updateTomanCurrencyHandler}
        />
        <RateContainer
          table={"Tehran Currency"}
          data={currencies}
          updateHandler={updateTomanCurrencyHandler}
        />
        <RateContainer
          table={"TRY Currency"}
          data={notTomanCurrencies}
          updateHandler={updateCurrencyHandler}
        />
        <RateContainer
          table={"USD Currency"}
          data={notTomanCurrencies}
          updateHandler={updateCurrencyHandler}
        />
        <RateContainer
          table={"Tehran Gold"}
          data={coins}
          updateHandler={updateCoinHandler}
        />
        <RateContainer
          table={"Istanbul Gold"}
          data={golds}
          updateHandler={updateGoldHandler}
        />
        <RateContainer
          table={"Universal"}
          data={noCategories}
          updateHandler={updateNoCategoryHandler}
        />
        <RateContainer
          table={"Crypto"}
          data={cryptos}
          updateHandler={updateCryptoHandler}
        />
      </div>
    </div>

    // <div className="exchange-rate-container">
    //   <NavBar logoutHandler={logoutHandler} />
    //   <div className="rate-container">
    //     <div className="gr-br-3">
    //       <div className="rate-table">
    //         <div className="header">
    //           <div className="inner-header">
    //             <div className="white">
    //               <span>{formatDate(latestCurrency?.updatedAt)} - </span>
    //               <span>{formatTime(latestCurrency?.updatedAt)}</span>
    //             </div>
    //             <div className="title-rate">
    //               Buying
    //               <div>
    //                 <input
    //                   className="rate-in"
    //                   value={buyRateChange.value}
    //                   onChange={(e) =>
    //                     setBuyRateChange({ ...buyRateChange, value: e.target.value })
    //                   }
    //                 />
    //                 <div>
    //                   <label>
    //                     <input
    //                       type="radio"
    //                       name="buyRateChange"
    //                       value="plus"
    //                       checked={buyRateChange.type === "plus"}
    //                       onChange={() => setBuyRateChange({ ...buyRateChange, type: "plus" })}
    //                     />
    //                     +
    //                   </label>
    //                   <label>
    //                     <input
    //                       type="radio"
    //                       name="buyRateChange"
    //                       value="minus"
    //                       checked={buyRateChange.type === "minus"}
    //                       onChange={() => setBuyRateChange({ ...buyRateChange, type: "minus" })}
    //                     />
    //                     -
    //                   </label>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="title-rate">
    //               Selling
    //               <div>
    //                 <input
    //                   className="rate-in"
    //                   value={sellRateChange.value}
    //                   onChange={(e) =>
    //                     setSellRateChange({ ...sellRateChange, value: e.target.value })
    //                   }
    //                 />
    //                 <div>
    //                   <label>
    //                     <input
    //                       type="radio"
    //                       name="sellRateChange"
    //                       value="plus"
    //                       checked={sellRateChange.type === "plus"}
    //                       onChange={() => setSellRateChange({ ...sellRateChange, type: "plus" })}
    //                     />
    //                     +
    //                   </label>
    //                   <label>
    //                     <input
    //                       type="radio"
    //                       name="sellRateChange"
    //                       value="minus"
    //                       checked={sellRateChange.type === "minus"}
    //                       onChange={() => setSellRateChange({ ...sellRateChange, type: "minus" })}
    //                     />
    //                     -
    //                   </label>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //         <div className="prices">
    //           {Array.isArray(updatedCurrencies) &&
    //             updatedCurrencies.map((item) => (
    //               <div className="item" key={`${item?.name}-USD`}>
    //                 <div className="g-3">
    //                   <div className="title-1">{item?.name} /USD</div>
    //                 </div>
    //                 <div className="g-2">
    //                   <input
    //                     className="rate-in"
    //                     value={`$${item?.USDBuyPrice.value}`}
    //                     onChange={(e) =>
    //                       handleInputChange(
    //                         item?.id,
    //                         "USDBuyPrice",
    //                         e.target.value,
    //                         buyRateChange.type
    //                       )
    //                     }
    //                   />
    //                   <input
    //                     className="rate-in"
    //                     value={`$${item?.USDSellPrice.value}`}
    //                     onChange={(e) =>
    //                       handleInputChange(
    //                         item?.id,
    //                         "USDSellPrice",
    //                         e.target.value,
    //                         sellRateChange.type
    //                       )
    //                     }
    //                   />
    //                 </div>
    //               </div>
    //             ))}
    //         </div>
    //         <div className="mode-selection">
    //           <div className="mode-title">
    //             <span>
    //               <img src={dolIcon} alt="dollar" />
    //             </span>
    //             <div>Choose how to show rates on the website</div>
    //           </div>
    //           <div className="radio-options">
    //             <label>
    //               <input
    //                 type="radio"
    //                 name="rateMode"
    //                 value="automatic"
    //                 checked={rateMode === "automatic"}
    //                 onChange={() => setRateMode("automatic")}
    //               />
    //               Automatic
    //             </label>
    //             <label>
    //               <input
    //                 type="radio"
    //                 name="rateMode"
    //                 value="manual"
    //                 checked={rateMode === "manual"}
    //                 onChange={() => setRateMode("manual")}
    //               />
    //               Manual
    //             </label>
    //           </div>
    //         </div>
    //         <div className="update-btn">
    //           <button
    //             className={isEdited ? "btn-edited" : ""}
    //             onClick={() => updateCurrencyHandler(updatedCurrencies)}
    //           >
    //             Update rates
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ExchangeRateOthersPage;
