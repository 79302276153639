import "../styles/ProfileSetting.scss";

import edit from "../assets/icons/edit.svg";
import close from "../assets/icons/x.svg";

import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";

const ProfileSettingPage = ({ logoutHandler }) => {
  const { REACT_APP_URL } = process.env;
  const userFromStore = useSelector((state) => state.user);

  const [isEditVisible, setIsEditVisible] = useState(false); // State to manage the visibility of the password edit section
  const [isUpdating, setIsUpdating] = useState(false); // For handling loading state during updates
  const [errorMessage, setErrorMessage] = useState(""); // For displaying errors
  const [prePassword, setPrePassword] = useState(""); // Current password state
  const [password, setPassword] = useState(""); // New password state
  const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password state

  // Get token from cookie
  const cookie = new Cookies();
  const authCookie = cookie.get("token");

  const handleEditClick = () => {
    setIsEditVisible(true); // Show the password edit section
  };

  const handleCloseClick = () => {
    setIsEditVisible(false); // Hide the password edit section
  };

  // Function to handle the password update
  const handleUpdatePassword = async () => {
    setIsUpdating(true);
    setErrorMessage(""); // Clear any previous error

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      setIsUpdating(false);
      return;
    }

    try {
      const response = await fetch(`${REACT_APP_URL}/auth/password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authCookie}`,
        },
        body: JSON.stringify({
          prePassword,
          password,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        // Update was successful
        // alert("Password updated successfully!");
        setIsEditVisible(false); // Close the edit section
      } else {
        throw new Error(result.message || "Failed to update the password.");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="profile-setting-container">
      <NavBar logoutHandler={logoutHandler} />
      <div className="profile-setting">
        <div className="info">
          <div className="item">
            <label>Email</label>
            <input
              type="email"
              disabled
              value={userFromStore.email}
              className="email"
            />
          </div>
          <div className="item">
            <label>Password</label>
            <div className="pass-in">
              <input value={"password"} type="password" disabled />
              <div className="gr-br-4">
                <button onClick={handleEditClick}>
                  <img src={edit} alt="edit" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Conditionally render the password edit section */}
        {isEditVisible && (
          <div className="pass-edit">
            <div className="title">
              <h1>New Password</h1>
            </div>
            <div className="bg-white">
              <div className="gr-br-6">
                <div className="close" onClick={handleCloseClick}>
                  <img src={close} alt="close" />
                </div>
              </div>
            </div>

            <div className="gr-br-5">
              <div className="edit">
                <div className="edit-items">
                  <div className="text">
                    To update your password, please fill in the fields below.
                    Make sure your new password is at least 8 characters long.
                  </div>
                  <div className="pass-item">
                    <label>Current password</label>
                    <input
                      type="password"
                      value={prePassword}
                      onChange={(e) => setPrePassword(e.target.value)}
                    />
                  </div>
                  <div className="pass-item">
                    <label>New password</label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="pass-item">
                    <label>Confirm new password</label>
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>

                {errorMessage && <p className="error">{errorMessage}</p>}

                <div className="reset-btn">
                  <button
                    onClick={handleUpdatePassword}
                    disabled={isUpdating}
                  >
                    {isUpdating ? "Updating..." : "Reset password"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSettingPage;
