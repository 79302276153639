import "../styles/LoginPage.scss";

import React, { useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

import { useDispatch, useSelector } from "react-redux";
import { setUserAction } from "../store/action";
import { Navigate } from "react-router-dom";

const LoginPage = ({ isAuthenticated, authHandler }) => {
  const { REACT_APP_URL, REACT_APP_TOKEN_EXPIRES_IN } = process.env;
  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // cookie
  const cookies = new Cookies();
  // initial dispatch
  const dispatch = useDispatch();
  // store
  const pathFromStore = useSelector((state) => state.path);

  // handlers
  const handleLoginClick = async (e) => {
    const date = new Date();
    // prevent default reload
    e.preventDefault();
    // input values
    const user = {
      email: email,
      password: password,
    };
    

    // request to login
    try {
      const res = await axios.post(`${REACT_APP_URL}/auth/login`, user, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log(res);

      dispatch(setUserAction(res.data.user));
      // set token in cookie
      date.setTime(date.getTime() + eval(REACT_APP_TOKEN_EXPIRES_IN));
      cookies.set("token", res.data.token, { expires: date });
      authHandler();
    } catch (err) {
      console.log(err);
    }
  };

  // Retrieve the last route after refresh
  if (isAuthenticated) {
    if (pathFromStore !== "/") {
      return <Navigate to={pathFromStore} />;
    } else {
      return <Navigate to="/exchange-rate" />;
    }
  }

  return (
    <div className="login-page">
      <div className="gr-br-1">
        <div className="login-container">
          <div className="input-container">
            <div className="input">
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div className="input">
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
          </div>
          <div className="login-btn">
            <button onClick={handleLoginClick}>Login</button>
            <div className="forgot-pass">Forgot password</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
