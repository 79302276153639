const initialState = {
  user: {},
  path: "",
};

const Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_PATH":
      return {
        ...state,
        path: action.path,
      };
    default:
      return state;
  }
};

export default Reducer;
