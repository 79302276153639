import "../styles/Messages.scss";
import Cookies from "universal-cookie";
import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";

const MessagesPage = ({ announcement, logoutHandler }) => {
  const { REACT_APP_URL } = process.env;

  const [dateValue, setDateValue] = useState(new Date());
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(announcement?.content);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showTooltip, setShowTooltip] = useState([]); // Array to store tooltip state per row
  const [feedbacks, setFeedbacks] = useState([]);

  const cookie = new Cookies();
  const authCookie = cookie.get("token");

  useEffect(() => {
    const getFeedbacks = async () => {
      setIsUpdating(true);
      setErrorMessage("");

      try {
        const response = await fetch(`${REACT_APP_URL}/feedback`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authCookie}`,
          },
        });

        const result = await response.json();

        if (response.ok) {
          setIsEditVisible(false);
          setFeedbacks(result.feedbacks);
          setShowTooltip(new Array(result.feedbacks.length).fill(false)); // Initialize tooltip state array
        } else {
          throw new Error(
            result.message || "Failed to update the announcement."
          );
        }
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setIsUpdating(false);
      }
    };
    getFeedbacks();
  }, []);

  const handleClick = (index) => {
    const newTooltipState = [...showTooltip];
    newTooltipState[index] = !newTooltipState[index]; // Toggle tooltip visibility on click
    setShowTooltip(newTooltipState);
  };

  const handleMouseEnter = (index) => {
    if (!showTooltip[index]) {
      const newTooltipState = [...showTooltip];
      newTooltipState[index] = true; // Temporarily show tooltip on hover
      setShowTooltip(newTooltipState);
    }
  };

  const handleMouseLeave = (index) => {
    if (!showTooltip[index]) {
      const newTooltipState = [...showTooltip];
      newTooltipState[index] = false; // Hide tooltip on mouse leave if not clicked
      setShowTooltip(newTooltipState);
    }
  };

  const charLimit = 100;

  return (
    <div className="messages-container">
      <NavBar logoutHandler={logoutHandler} />
      <div className="messages">
        <div className="msg-table">
          <div className="titles">
            <div>Row</div>
            <div>Name</div>
            <div>Email</div>
            <div>Message</div>
          </div>
          <div className="row-container">
            {feedbacks && feedbacks.length > 0 ? (
              feedbacks.map((feedback, index) => {
                const truncatedText = feedback.message.substring(0, charLimit);
                return (
                  <div key={feedback.id || index} className="rows">
                    <div className="number">{index + 1}</div>
                    <div>{feedback.name}</div>
                    <div>{feedback.email}</div>
                    <div
                      className="message-content"
                      onClick={() => handleClick(index)}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                      {showTooltip[index] ? (
                        <span className="tooltip">
                          {feedback.message} {/* Full content shown */}
                        </span>
                      ) : (
                        <span>{truncatedText + "..."}</span> // Show truncated content with ellipsis
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No feedbacks available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
