import "../styles/Advertisement.scss";

import React, { useState } from "react";
import NavBar from "../components/NavBar";

const AdvertisementPage = ({ logoutHandler }) => {
  return (
    <div className="advertisement-container">
      <NavBar logoutHandler={logoutHandler} />
      <h1>Coming soon ...</h1>
    </div>
  );
};

export default AdvertisementPage;
