import React, { useEffect, useState } from "react";
import axios from "axios";
import dolIcon from "../../assets/icons/paid.svg";
import { logDOM } from "@testing-library/react";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

const RateContainer = ({ table, data, updateHandler }) => {
  console.log(table, data);

  const [updatedData, setUpdatedData] = useState(data);
  const [isEdited, setIsEdited] = useState(false);
  const [rateMode, setRateMode] = useState("automatic");
  const { REACT_APP_URL } = process.env;

  const formatField = (item, field) => {
    // console.log(table, item, field);

    switch (table) {
      case "Istanbul Currency":
        return field === "buyPrice"
          ? item.istanbulBuyPrice
          : item.istanbulSellPrice;
      case "Tehran Currency":
        return field === "buyPrice"
          ? item.tehranBuyPrice
          : item.tehranSellPrice;
      case "TRY Currency":
        return field === "buyPrice" ? item.TRYBuyPrice : item.TRYSellPrice;
      case "USD Currency":
        return field === "buyPrice" ? item.USDBuyPrice : item.USDSellPrice;
      case "Tehran Gold":
        return field === "buyPrice" ? item.buyPrice : item.sellPrice;
      case "Istanbul Gold":
        return field === "buyPrice"
          ? item.istanbulBuyPrice
          : item.istanbulSellPrice;
      case "Universal":
        return field === "buyPrice" ? item.buyPrice : item.sellPrice;
      case "Crypto":
        return field === "buyPrice" ? item.buyPrice : item.sellPrice;
      default:
        return "";
    }
  };

  const updateField = (item, field, value) => {
    const fieldKey =
      table === "Istanbul Currency"
        ? field === "buyPrice"
          ? "istanbulBuyPrice"
          : "istanbulSellPrice"
        : table === "Tehran Currency"
        ? field === "buyPrice"
          ? "tehranBuyPrice"
          : "tehranSellPrice"
        : table === "TRY Currency"
        ? field === "buyPrice"
          ? "TRYBuyPrice"
          : "TRYSellPrice"
        : table === "USD Currency"
        ? field === "buyPrice"
          ? "USDBuyPrice"
          : "USDSellPrice"
        : table === "Tehran Gold"
        ? field === "buyPrice"
          ? "buyPrice"
          : "sellPrice"
        : table === "Istanbul Gold"
        ? field === "buyPrice"
          ? "istanbulBuyPrice"
          : "istanbulSellPrice"
        : table === "Universal"
        ? field === "buyPrice"
          ? "buyPrice"
          : "sellPrice"
        : table === "Crypto"
        ? field === "buyPrice"
          ? "buyPrice"
          : "sellPrice"
        : field;

    return { ...item, [fieldKey]: value };
  };

  // Helper function to get the correct rate change field based on table
  const getRateFieldKey = (table, field) => {
    switch (table) {
      case "Istanbul Currency":
        return field === "buyRateChange"
          ? "istanbulBuyRateChange"
          : "istanbulSellRateChange";
      case "Tehran Currency":
        return field === "buyRateChange"
          ? "tehranBuyRateChange"
          : "tehranSellRateChange";
      case "TRY Currency":
        return field === "buyRateChange"
          ? "TRYBuyRateChange"
          : "TRYSellRateChange";
      case "USD Currency":
        return field === "buyRateChange"
          ? "USDBuyRateChange"
          : "USDSellRateChange";
      case "Istanbul Gold":
        return field === "buyRateChange"
          ? "istanbulBuyRateChange"
          : "istanbulSellRateChange";
      case "Tehran Gold":
      case "Crypto":
      case "Universal":
        return field; // For these tables, use the same rateChange field name
      default:
        return field;
    }
  };

  // Find the currency with the latest `updatedAt`
  const latestCurrency = data?.reduce((latest, item) => {
    return new Date(item?.updatedAt) > new Date(latest.updatedAt)
      ? item
      : latest;
  }, data[0]);

  useEffect(() => {
    setUpdatedData(data);
    switch (table) {
      case "Istanbul Currency":
        setRateMode(
          data[0]?.isAutoFillIstanbul === true ? "automatic" : "manual"
        );
        break;
      case "Tehran Currency":
        setRateMode(
          data[0]?.isAutoFillTehran === true ? "automatic" : "manual"
        );
        break;
      case "TRY Currency":
        setRateMode(data[0]?.isAutoFillTRY === true ? "automatic" : "manual");
        break;
      case "USD Currency":
        setRateMode(data[0]?.isAutoFillUSD === true ? "automatic" : "manual");
        break;
      default:
        setRateMode(data[0]?.isAutoFill === true ? "automatic" : "manual");
        break;
    }
  }, [data]);

  const handleInputChange = (id, field, value) => {
    const newCurrs = updatedData.map((coin) => {
      if (coin.id === id) {
        return updateField(coin, field, value.replace(/[^0-9.]/g, ""));
      }
      return coin;
    });

    setUpdatedData(newCurrs);
    setIsEdited(true);
  };

  const handleUpdateRates = () => {
    // console.log(updatedData);
    updateHandler(updatedData);
    setIsEdited(false);
  };

  const getRateChangeType = (rateChange) => {
    return rateChange?.includes("-") ? "minus" : "plus";
  };

  const handleRateChange = (id, field, type, value) => {
    const fieldKey = getRateFieldKey(table, field); // Get correct rate change field
    const newRateChange =
      type === "plus" ? value : `-${value.replace(/^-/, "")}`;
    const newCoins = updatedData.map((coin) => {
      if (coin.id === id) {
        return { ...coin, [fieldKey]: newRateChange };
      }
      return coin;
    });

    setUpdatedData(newCoins);
    setIsEdited(true);

    updateHandler(newCoins);
  };

  const handleRateModeChange = async (mode) => {
    let tableURL = "";

    switch (table) {
      case "Istanbul Currency":
        tableURL = "currency/toman/Istanbul-mode";
        break;
      case "Tehran Currency":
        tableURL = "currency/toman/tehran-mode";
        break;
      case "TRY Currency":
        tableURL = "currency/try-mode";
        break;
      case "USD Currency":
        tableURL = "currency/usd-mode";
        break;
      case "Istanbul Gold":
        tableURL = "gold";
        break;
      case "Tehran Gold":
        tableURL = "coin";
        break;
      case "Crypto":
        tableURL = "currency/crypto";
        break;
      case "Universal":
        tableURL = "no-category";
        break;
      default:
        tableURL = "";
        break;
    }

    try {
      setRateMode(mode); // Update local state immediately
      await axios.patch(`${REACT_APP_URL}/${tableURL}`, {
        rateMode: mode,
      }); // Replace with your API endpoint
      console.log("Rate mode updated to:", mode);
    } catch (error) {
      console.error("Failed to update rate mode:", error);
    }
  };

  return (
    <div className="rate-container">
      <div className="gr-br-3">
        <div className="rate-table">
          <div className="header">
            <div className="inner-header">
              <div className="white">
                <span>{`${table} - `}</span>
                <span>{formatDate(latestCurrency?.updatedAt)} - </span>
                <span>{formatTime(latestCurrency?.updatedAt)}</span>
              </div>
              <div>Buying</div>
              <div>Selling</div>
            </div>
          </div>
          <div className="prices">
            {updatedData.map((item) => (
              <div className="item" key={`${item?.name}-tehran`}>
                <div className="g-3">
                  <div className="title-1">{item?.name}</div>
                </div>
                <div className="g-2">
                  {/* Disable buyPrice input if rateMode is automatic */}
                  <div className="flex">
                    <input
                      className="rate-in"
                      value={`$${formatField(item, "buyPrice")}`}
                      onChange={(e) =>
                        handleInputChange(item?.id, "buyPrice", e.target.value)
                      }
                      disabled={rateMode === "automatic"}
                    />
                    <div className="changes">
                      <div className="radio-options">
                        <input
                          type="radio"
                          name={`buyRateChange-${table}-${item?.id}`}
                          id={`buy-minus-${table}-${item?.id}`}
                          value="minus"
                          checked={
                            getRateChangeType(
                              item[getRateFieldKey(table, "buyRateChange")]
                            ) === "minus"
                          }
                          onChange={() =>
                            handleRateChange(
                              item?.id,
                              "buyRateChange",
                              "minus",
                              item[
                                getRateFieldKey(table, "buyRateChange")
                              ].replace(/^[-+]/, "")
                            )
                          }
                        />
                        <label htmlFor={`buy-minus-${table}-${item?.id}`}>
                          -
                        </label>

                        <input
                          type="radio"
                          name={`buyRateChange-${table}-${item?.id}`}
                          id={`buy-plus-${table}-${item?.id}`}
                          value="plus"
                          checked={
                            getRateChangeType(
                              item[getRateFieldKey(table, "buyRateChange")]
                            ) === "plus"
                          }
                          onChange={() =>
                            handleRateChange(
                              item?.id,
                              "buyRateChange",
                              "plus",
                              item[
                                getRateFieldKey(table, "buyRateChange")
                              ].replace(/^[-+]/, "")
                            )
                          }
                        />
                        <label htmlFor={`buy-plus-${table}-${item?.id}`}>
                          +
                        </label>
                      </div>
                      <input
                        className="c-in"
                        value={item[
                          getRateFieldKey(table, "buyRateChange")
                        ].replace(/^[-+]/, "")}
                        onChange={(e) =>
                          handleRateChange(
                            item?.id,
                            "buyRateChange",
                            getRateChangeType(
                              item[getRateFieldKey(table, "buyRateChange")]
                            ),
                            e.target.value
                          )
                        }
                        onBlur={handleUpdateRates} // Call handleUpdateRates on leaving the input
                      />
                    </div>
                  </div>
                  <div className="flex">
                    {/* Disable sellPrice input if rateMode is automatic */}
                    <input
                      className="rate-in"
                      value={`$${formatField(item, "sellPrice")}`}
                      onChange={(e) =>
                        handleInputChange(item?.id, "sellPrice", e.target.value)
                      }
                      disabled={rateMode === "automatic"}
                    />
                    <div className="changes">
                      <div className="radio-options">
                        <input
                          type="radio"
                          name={`sellRateChange-${table}-${item?.id}`}
                          id={`sell-minus-${table}-${item?.id}`}
                          value="minus"
                          checked={
                            getRateChangeType(
                              item[getRateFieldKey(table, "sellRateChange")]
                            ) === "minus"
                          }
                          onChange={() =>
                            handleRateChange(
                              item?.id,
                              "sellRateChange",
                              "minus",
                              item[
                                getRateFieldKey(table, "sellRateChange")
                              ].replace(/^[-+]/, "")
                            )
                          }
                        />
                        <label htmlFor={`sell-minus-${table}-${item?.id}`}>
                          -
                        </label>

                        <input
                          type="radio"
                          name={`sellRateChange-${table}-${item?.id}`}
                          id={`sell-plus-${table}-${item?.id}`}
                          value="plus"
                          checked={
                            getRateChangeType(
                              item[getRateFieldKey(table, "sellRateChange")]
                            ) === "plus"
                          }
                          onChange={() =>
                            handleRateChange(
                              item?.id,
                              "sellRateChange",
                              "plus",
                              item[
                                getRateFieldKey(table, "sellRateChange")
                              ].replace(/^[-+]/, "")
                            )
                          }
                        />
                        <label htmlFor={`sell-plus-${table}-${item?.id}`}>
                          +
                        </label>
                      </div>
                      <input
                        className="c-in"
                        value={item[
                          getRateFieldKey(table, "sellRateChange")
                        ].replace(/^[-+]/, "")}
                        onChange={(e) =>
                          handleRateChange(
                            item?.id,
                            "sellRateChange",
                            getRateChangeType(
                              item[getRateFieldKey(table, "sellRateChange")]
                            ),
                            e.target.value
                          )
                        }
                        onBlur={handleUpdateRates} // Call handleUpdateRates on leaving the input
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mode-selection">
            <div className="mode-title">
              <span>
                <img src={dolIcon} alt="dollar" />
              </span>
              <div>Choose how to show rates on the website</div>
            </div>
            <div className="radio-options">
              <label>
                <input
                  type="radio"
                  name={`${table}-rateMode`}
                  value="automatic"
                  checked={rateMode === "automatic"}
                  onChange={() => handleRateModeChange("automatic")}
                />
                Automatic
              </label>
              <label>
                <input
                  type="radio"
                  name={`${table}-rateMode`}
                  value="manual"
                  checked={rateMode === "manual"}
                  onChange={() => handleRateModeChange("manual")}
                />
                Manual
              </label>
            </div>
          </div>
          <div className="update-btn">
            <button
              className={isEdited ? "btn-edited" : ""}
              onClick={handleUpdateRates}
            >
              Update rates
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateContainer;
