export const setUserAction = (val) => {
  return {
    type: "SET_USER",
    user: val,
  };
};


export const setPathAction = (val) => {
  return {
    type: "SET_PATH",
    path: val,
  };
};
