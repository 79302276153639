import "../styles/Announcement.scss";

import edit from "../assets/icons/edit.svg";
import close from "../assets/icons/x.svg";
import trash from "../assets/icons/trash-2.svg";
import calendar from "../assets/icons/calendar.svg";

import Cookies from "universal-cookie";

import DatePicker from "react-multi-date-picker";
import React, { useState } from "react";
import NavBar from "../components/NavBar";

const AnnouncementPage = ({ announcement, logoutHandler }) => {
  const { REACT_APP_URL } = process.env;

  const [dateValue, setDateValue] = useState(new Date());
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [updatedContent, setUpdatedContent] = useState(announcement?.content); // New state for updated content
  const [isUpdating, setIsUpdating] = useState(false); // For handling loading state during updates
  const [errorMessage, setErrorMessage] = useState(""); // For displaying errors

  // get token from cookie
  const cookie = new Cookies();
  const authCookie = cookie.get("token");

  const handleEditClick = () => {
    setIsEditVisible(true);
  };

  const handleCloseClick = () => {
    setIsEditVisible(false);
  };

  // Function to handle the announcement update
  const handleUpdateAnnouncement = async () => {
    setIsUpdating(true);
    setErrorMessage(""); // Clear any previous error

    try {
      const response = await fetch(`${REACT_APP_URL}/announcement`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authCookie}`,
        },
        body: JSON.stringify({
          content: updatedContent,
          startDate: new Date(), // Assuming start date is today
          endDate: dateValue,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        // Update was successful
        // alert("Announcement updated successfully!");
        setIsEditVisible(false); // Close the edit section
      } else {
        throw new Error(result.message || "Failed to update the announcement.");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  // Function to handle announcement deletion
  const handleDeleteAnnouncement = async () => {
    setIsUpdating(true);
    setErrorMessage(""); // Clear any previous error

    try {
      const response = await fetch(`${REACT_APP_URL}/announcements/update`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authCookie}`,
        },
        body: JSON.stringify({
          content: null,
          startDate: null,
          endDate: null,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        // alert("Announcement deleted successfully!");
        // Optionally, you can clear the state to remove the announcement
      } else {
        throw new Error(result.message || "Failed to delete the announcement.");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="announcement-container">
      <NavBar logoutHandler={logoutHandler} />
      <div className="announcement">
        <div className="info">
          <div className="item">
            <label>Current announcement</label>
            <textarea
              type="text"
              disabled
              value={announcement?.content}
              className="ann-text"
            />
          </div>
          <div className="item">
            <label>Date Range</label>
            <div className="pass-in">
              <input value={"29/10/2023 - 29/11/2023"} disabled />
            </div>
          </div>

          <div className="item ann-btn">
            <button className="del-btn" onClick={handleDeleteAnnouncement}>
              <img src={trash} alt="trash" />
              Delete Announcement
            </button>
            <button className="edit-btn" onClick={handleEditClick}>
              <img src={edit} alt="edit" />
              Edit Announcement
            </button>
          </div>
        </div>

        {isEditVisible && (
          <div className="pass-edit">
            <div className="title">
              <h1>Edit Announcement</h1>
            </div>
            <div className="bg-white">
              <div className="gr-br-6">
                <div className="close" onClick={handleCloseClick}>
                  <img src={close} alt="close" />
                </div>
              </div>
            </div>

            <div className="gr-br-5">
              <div className="edit">
                <div className="edit-items">
                  <div className="text">
                    You can update the entire announcement details here. Modify
                    the title, message, or date range as needed.
                  </div>
                  <div className="pass-item">
                    <label>Announcement text</label>
                    <textarea
                      type="text"
                      value={updatedContent}
                      onChange={(e) => setUpdatedContent(e.target.value)}
                      className="ann-text"
                    />
                  </div>
                  <div className="pass-item">
                    <label>End date</label>
                    <DatePicker
                      value={dateValue}
                      onChange={setDateValue}
                      minDate={new Date()} // Set the minimum date to today
                    />
                    <img src={calendar} alt="calendar" className="calendar" />
                  </div>
                </div>

                <div className="reset-btn">
                  <button
                    onClick={handleUpdateAnnouncement}
                    disabled={isUpdating}
                  >
                    {isUpdating ? "Updating..." : "Update Announcement"}
                  </button>
                </div>
                {errorMessage && <div className="error">{errorMessage}</div>}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnnouncementPage;
